<template>
    <div class="login-vue" :style="{backgroundImage: `url(${loginBackgroundImageUrl})`}">
        <!-- logo区域 -->
        <div v-show="logoUrl" class="logo-item">
            <img style="width:100%;height:100%" :src="logoUrl" alt />
        </div>
        <!-- 表单区域 -->
        <div class="container">
            <div class="content">
                <h1>
                    <!-- <i>{{帮客融助贷}}</i> -->
                    <i>{{ title }}</i>
                    <!-- <span>管理系统</span> -->
                </h1>
                <p class="one">
                    <span />
                    <el-input v-model="username" placeholder="请输入用户名" />
                </p>
                <p class="two">
                    <span />
                    <el-input
                        v-model="password"
                        type="password"
                        placeholder="请输入密码"
                        @keydown.enter.native="submit"
                    />
                </p>
                <p class="three">
                    <el-input
                        v-model="verificationCode"
                        placeholder="请输入验证码"
                        onkeyup="value=value.replace(/[^\d]/g,'')"
                    />
                    <el-button v-if="show" type="primary" @click="fsyzm">发送验证码</el-button>
                    <el-button v-if="!show" type="primary" disabled>{{ count }}</el-button>
                </p>
                <el-button type="primary" @click="submit">登录</el-button>
                <div class="mm">
                    <el-checkbox v-model="checked">记住密码</el-checkbox>
                    <!-- <span>忘记密码></span> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import {
// 	all_value,
// 	login_user
// } from "@/api/public_content";
import { login_user } from '../api/public_content.js';
import { login, yzm } from '@/api/myclueApi';
// import { clues_new } from "@/api/my_clue.js";
import { styleInfo, areas, all_value } from '@/api/public_content';
// import { clues_new } from "../api/my_clue.js";
// import { clueData,  } from "@/api/public_content";
let softPhoneUA = '';
export default {
    name: 'Login',
    data() {
        return {
            username: '',
            password: '',
            verificationCode: '',
            accountError: '',
            pwdError: '',
            // bg: {

            // },
            checked: false,
            show: true,
            count: '',
            timer: null,
            title: '客户管理系统',
            logoUrl: '',
            loginBackgroundImageUrl: require('@/assets/default_background.jpg'),
        };
    },
    activated() {
        if (this.debugOpen) console.log('login.vue -> activated()');
    },
    created() {
        if (this.debugOpen) console.log('login.vue -> created()');
        
        //  到这个页面后，表示已经退出系统，需要清除缓存
        sessionStorage.clear();
        //  设置默认 logo
        let logoUrl = require('@/assets/default_logo.png');
        sessionStorage.setItem('logoUrl', logoUrl);
        this.logoUrl = logoUrl;

        // 判断路由携带的参数取出公司
        let val = this.common.getQueryString('prefix');
        sessionStorage.setItem('prefix', val);
        this.getCookie();
        this.getStyleInfo(val);
    },
    mounted() {
        if (this.debugOpen) console.log('login.vue -> mounted()');
    },
    methods: {
        // 获取验证码
        fsyzm() {
            yzm({
                loginName: this.username,
            }).then((res) => {
                if (res.code !== 0) {
                    const TIME_COUNT = 60;
                    if (!this.timer) {
                        this.count = TIME_COUNT;
                        this.show = false;
                        this.timer = setInterval(() => {
                            if (this.count > 0 && this.count <= TIME_COUNT) {
                                this.count--;
                            } else {
                                this.show = true;
                                clearInterval(this.timer);
                                this.timer = null;
                            }
                        }, 1000);
                    }
                } else {
                    this.$message.error(res.error);
                }
            });
        },
        //登陆事件
        submit() {
            if (this.username === '') {
                this.$message({
                    message: '请填写用户名',
                    type: 'warning',
                });
            } else if (this.password === '') {
                this.$message({
                    message: '请填写密码',
                    type: 'warning',
                });
            }
            // else if (this.verificationCode === "") {
            //   this.$message({
            //     message: "请填写验证码",
            //     type: "warning",
            //   });
            // }
            else {
                login({
                    username: this.username,
                    password: this.password,
                    verificationCode: this.verificationCode,
                })
                    .then((res) => {
                        if (res && res.code != '0') {
                            this.$message({
                                message: res.message,
                                type: 'error',
                            });
                            return;
                        }

                        //  请求行政区划数据, 并作缓存
                        let areasStorage = sessionStorage.getItem('areas');
                        if (!areasStorage) {
                            console.error('加载行政区划数据');
                            areas({
                                level: 2,
                            }).then((res) => {
                                if (res.code == '0') {
                                    console.error('加载行政区划数据完成...');
                                    let jsonData = JSON.stringify(
                                        res.data.list
                                    );
                                    //  将空数组转为 null, 以解决使用 element ui 联级选择器时, 子节点为空数组时, 还会展示下级选项
                                    jsonData = jsonData.replaceAll(
                                        '[]',
                                        'null'
                                    );
                                    sessionStorage.setItem('areas', jsonData);
                                } else {
                                    console.error('加载行政区划数据失败...');
                                }
                            });
                        }

                        //  请求字典数据, 并作缓存
                        let dictionariesStorage =
                            sessionStorage.getItem('dictionaries');
                        if (!dictionariesStorage) {
                            if (this.debugOpen)
                                console.log(
                                    'login.vue -> methods -> submit() -> login(): 加载字典数据'
                                );
                            all_value().then((res) => {
                                if (res.code == '0') {
                                    if (this.debugOpen)
                                        console.log(
                                            'login.vue -> methods -> submit() -> login(): 加载字典数据完成...'
                                        );
                                    sessionStorage.setItem(
                                        'dictionaries',
                                        JSON.stringify(res.data.list)
                                    );
                                } else {
                                    if (this.debugOpen)
                                        console.log(
                                            'login.vue -> methods -> submit() -> login(): 加载字典数据失败...'
                                        );
                                }
                            });
                        }

                        sessionStorage.setItem('token', res.data.token);
                        sessionStorage.setItem(
                            'refreshToken',
                            res.data.refreshToken
                        );
                        sessionStorage.setItem('code', res.code);
                        // this.$EventBus.$emit('getAllValue');
                        // this.$EventBus.$emit('sipRegister');
                        this.$message({
                            message: '登录成功',
                            type: 'success',
                        });
                        if (this.debugOpen)
                            console.log(
                                'login.vue -> methods -> submit() -> login()',
                                '登录成功'
                            );
                        // this.$nextTick(() => {
                        //   location.reload();
                        // });
                        // location.reload();
                        const self = this;
                        if (self.checked == true) {
                            self.setCookie(self.username, self.password, 7);
                        } else {
                            self.clearCookie();
                        }

                        // clues_new().then((res) => {
                        //   sessionStorage.setItem("data", JSON.stringify(res.data.list));
                        //   this.$store.state.num = res.data.list.length;
                        // });
                        // let that = this
                        // clueData().then((res) => {
                        //   if (res.code == 0) {
                        //       that.resetSetItem("clueDataNum", JSON.stringify(res.data));
                        //   } else {
                        //     this.$message.error(res.message);
                        //   }
                        // });
                        this.$nextTick(() => {
                            this.$router.push({
                                path: '/home',
                            });
                        });
                    })
                    .catch((err) => {
                        this.$message.error(err.data.error);
                    });

                // let f1 = new Promise((reslove, reject) => {
                //   login({
                //     username: this.username,
                //     password: this.password,
                //     verificationCode: this.verificationCode,
                //   })
                //     .then((res) => {
                //       sessionStorage.setItem("token", res.data.token);
                //       sessionStorage.setItem("refreshToken", res.data.refreshToken);
                //       sessionStorage.setItem("code", res.code);
                //       this.$message({
                //         message: "登陆成功",
                //         type: "success",
                //       });
                //     })
                //     .catch((err) => {
                //       this.$message.error(err.data.error);
                //     });
                // });
                // f1.then(() => {
                //   location.reload();
                //   const self = this;
                //   if (self.checked == true) {
                //     self.setCookie(self.username, self.password, 7);
                //   } else {
                //     self.clearCookie();
                //   }
                // });
            }
        },

        setCookie(c_name, c_pwd, exdays) {
            let exdate = new Date(); //获取时间
            exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); //保存的天数
            //字符串拼接cookie
            window.document.cookie =
                'userName' +
                '=' +
                c_name +
                ';path=/;expires=' +
                exdate.toGMTString();
            window.document.cookie =
                'userPwd' +
                '=' +
                c_pwd +
                ';path=/;expires=' +
                exdate.toGMTString();
        },
        getCookie: function () {
            if (document.cookie.length > 0) {
                let arr = document.cookie.split('; '); //这里显示的格式需要切割一下自己可输出看下 // console.log(arr)
                for (let i = 0; i < arr.length; i++) {
                    let arr2 = arr[i].split('='); //再次切割 //判断查找相对应的值
                    if (arr2[0] == 'userName') {
                        this.username = arr2[1]; //保存到保存数据的地方
                    } else if (arr2[0] == 'userPwd') {
                        this.password = arr2[1];
                    }
                }
            }
        },
        clearCookie: function () {
            this.setCookie('', '', -1); //修改2值都为空，天数为负1天就好了
        },
        // 跟剧参数获得信息
        getStyleInfo(val) {
            styleInfo({
                loginPrefix: val,
            }).then((res) => {
                if (res.code == 0) {
                    this.title = res.data.loginTitle;
                    this.loginBackgroundImageUrl =
                        res.data.loginBackgroundImageUrl;
                    this.logoUrl = res.data.logoImageUrl;
                    sessionStorage.setItem('logoUrl', this.logoUrl);
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.login-vue {
    min-width: 1349px;
    min-height: 635px;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    // background: url(../../public/images/bg.png);
    background-size: 100% 100%;
    position: relative;
}

i {
    font-style: normal;
}

.login-vue .container {
    text-align: center;
    position: relative;

    .content > ::v-deep .el-button {
        width: 100%;
        background-image: #3b8bee;
        margin-bottom: 20px;
        margin-top: 5px;
    }

    .content {
        position: relative;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        padding: 40px 30px;

        h1 {
            font-size: 22px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: rgba(70, 70, 70, 1);
            margin-bottom: 20px;

            span {
                color: #0078c2;
            }
        }

        ::v-deep .el-input {
            width: calc(100% - 70px);

            input {
                border: 0;
                border-bottom: 1px solid #f5f5f5;
            }
        }

        ::v-deep .el-input__inner {
            border-radius: 10px;
        }

        p {
            position: relative;

            span {
                position: absolute;
                top: 9px;
                left: 14px;
                width: 18px;
                height: 20px;
                display: inline-block;
            }
        }

        .one {
            span {
                background: url(../../public/images/user.png);
                background-size: 100% 100%;
                z-index: 2;
            }
        }

        .two {
            span {
                width: 20px;
                height: 20px;
                background: url(../../public/images/password.png);
                background-size: 100% 100%;
                z-index: 2;
            }
        }

        .three {
            ::v-deep .el-input {
                width: 60%;

                input {
                    border: 0;
                    border-bottom: 1px solid #f5f5f5;
                }
            }

            ::v-deep .el-button {
                width: 40%;

                span {
                    display: inline-block;
                    width: 70px;
                }
            }
        }

        .mm {
            overflow: hidden;

            ::v-deep .el-checkbox {
                float: left;
            }

            span {
                display: block;
                float: right;
                font-size: 14px;
                width: auto;
                color: #409eff;
                cursor: pointer;
            }
        }
    }
}

p {
    margin-top: 10px;
    margin-bottom: 10px;
}

.logo-item {
    width: 118px;
    height: 41px;
    position: absolute;
    top: 50px;
    left: 50px;
}
</style>
